export function gtagEvent(
    code: string,
    params: {
        event_category: string
        event_label: string
    },
) {
    if (window.gtag) {
        //console.log('ga', code, params)
        window.gtag('event', code, params)
    }
}
