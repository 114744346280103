import React from 'react'
import { Login } from '../../components/pages/login/Login'
import { NextPageContext } from 'next'
import { getEnv } from '../../utils/server-side/getEnv'

export default function LoginPage(props: any) {
    return <Login {...props} />
}

export async function getServerSideProps(context: NextPageContext) {
    const { Config } = require('../../config')
    //LINKED IN
    let params: Record<string, any> = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_type: 'code',
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: Config.LINKEDIN_CLIENT_ID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: Config.LINKEDIN_REDIRECT_URI,
        state: Math.random().toString(),
        scope: 'r_liteprofile r_emailaddress',
    }
    let query = []
    for (const k in params) {
        query.push(`${k}=${encodeURIComponent(params[k])}`)
    }
    const linkedInLink = `https://www.linkedin.com/oauth/v2/authorization?${query.join('&')}`
    // GOOGLE
    params = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_type: 'code',
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: Config.GOOGLE_CLIENT_ID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: Config.GOOGLE_REDIRECT_URI,
        state: Math.random().toString(),
        // eslint-disable-next-line @typescript-eslint/camelcase
        access_type: 'online',
        scope: 'email',
        prompt: 'select_account',
    }

    query = []
    for (const k in params) {
        query.push(`${k}=${encodeURIComponent(params[k])}`)
    }
    const googleLink = `https://accounts.google.com/o/oauth2/v2/auth?${query.join('&')}`
    return { props: { linkedInLink, googleLink, mfaEnabled: Config.ENABLED_MFA, ...getEnv() } }
}
