import React, { PropsWithChildren, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import Head from 'next/head'
import { Field, Form } from 'react-final-form'
import InputAdapter from '../../controls/inputs/InputAdapter'
import { store } from '../../../store'
import { SagaHelper } from '../../../store/sagaHelper'
import { Provider } from 'react-redux'
import { StatusMessage } from '../../controls/StatusMessage'
import { yupValidateObject } from '../../../utils'
import * as yup from 'yup'
import { REG_EXP } from '../../../utils/constants/regExps'
import { hideProgress, showErrorNotification, showProgress } from '../fullproject/notifications'
import { useRouter } from 'next/router'
import { ROUTE } from '../../../utils/constants/routes'
import { USER_FLOW } from '../../../utils/constants/projectStepIds'
import { myStorage } from '../../../store/storage'
import { FormApi } from 'final-form'
import { gtagEvent } from '../../../utils/ga'

export function Login(props: PropsWithChildren<any>) {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
    const [userFlow, setUserFlow] = useState<string>('')
    const [showlogin, setShowLogin] = useState<boolean>(true)
    const [showSMSCode, setShowSMSCode] = useState<boolean>(false)
    const isMFAEnabled = props.mfaEnabled // Config.ENABLED_MFA

    const [localContext, setValues] = useState<any>(false)

    const router = useRouter()
    const togglePasswordVisible = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()
            setPasswordVisible(!passwordVisible)
        },
        [passwordVisible],
    )
    async function doSuccess() {
        if (router.query.redirect) {
            await router.replace(router.query?.redirect as string)
        } else if (userFlow === USER_FLOW.LENDER) {
            await router.replace(ROUTE.LENDER_PROJECTS)
        } else {
            await router.replace(ROUTE.PROJECTS)
        }
    }
    const skipForNow = useCallback(async () => {
        doSuccess()
    }, [])

    const resendSmsCode = useCallback(async () => {
        let sobj = myStorage.getItem('smsCode')
        if (sobj == null || sobj.count == null) {
            sobj = { count: 0, last: 0 }
            myStorage.setItem('smsCode', sobj)
        }

        async function sendSms() {
            //console.log('send sms', sobj.count)
            sobj.count = sobj.count + 1
            sobj.last = new Date().getTime()
            myStorage.setItem('smsCode', sobj)

            const response = await SagaHelper.run(['login', 'login'], localContext)
            //console.log('validation response', response)
            if (response.type == 'error') {
                await showErrorNotification(response.message)
                await hideProgress()
                return
            }
        }
        myStorage.get
        if (sobj.count == 0) {
            sendSms()
        } else if (sobj.count < 5) {
            const now = new Date().getTime()
            if (now - sobj.last > 1000 * 60) {
                sendSms()
            } else {
                //console.log('sobj.last', sobj.last)
                // console.log('now', now)
                // console.log('dif', (now - sobj.last) / 1000)
                await showErrorNotification(
                    'can only send in ' + Math.floor(60 - (now - sobj.last) / 1000) + ' seconds',
                )
                await hideProgress()
                return
            }
        } else {
            await showErrorNotification('too many Attempts, please contact support')
            await hideProgress()
            return
        }
    }, [localContext])

    async function doSMS(values: { username: string; password: string; smscode: string }) {
        await showProgress()
        try {
            const response = await SagaHelper.run(['login', 'login'], values)
            //console.log('validation response', response)
            if (response.type == 'error') {
                await showErrorNotification(response.message)
                await hideProgress()
                return
            }
            setUserFlow(response.flow)
            doSuccess()
        } catch (e) {
            await showErrorNotification(e.message)
            await hideProgress()
        }
    }

    async function doLogin(values: { username: string; password: string; smscode: string }) {
        await showProgress()
        try {
            const response = await SagaHelper.run(['login', 'login'], values)

            await SagaHelper.run(['notification', 'show'], response.message, response.type)
            if (response.type == 'error') {
                await showErrorNotification(response.message)
                await hideProgress()
                try {
                    gtagEvent('login_failure', {
                        event_category: 'User Login Error ',
                        event_label: '' + values.username,
                    })
                } catch (e) {
                    // console.log('e', e)
                }
                return
            }

            try {
                gtagEvent('login_Success', {
                    event_category: 'User Login Success ',
                    event_label: '' + values.username,
                })
            } catch (e) {
                // console.log('e', e)
            }

            //console.log('response doLogin', response)
            if (response.sendMFACode) {
                setShowLogin(false)
                setShowSMSCode(true)
            } else {
                setUserFlow(response.flow)
                doSuccess()
            }
        } catch (e) {
            await showErrorNotification(e.message)
            await hideProgress()
        }
    }
    const handleSubmit = useCallback(
        async (values: { username: string; password: string; smscode: string }) => {
            //console.log('showlogin showSMS', showlogin, showSMSCode)
            setValues(values)
            if (showlogin) {
                const errors = yupValidateObject(
                    yup.object().shape({
                        username: yup.mixed().test('username', 'Please enter your Username', (value) => {
                            return value && REG_EXP.NO_EMPTY.test(value)
                        }),
                        password: yup.mixed().test('password', 'Please provide a password', (value) => {
                            return value && REG_EXP.NO_EMPTY.test(value)
                        }),
                    }),
                    values,
                )
                if (errors) {
                    return errors
                }
                doLogin(values)
            } else if (showSMSCode) {
                const errors = yupValidateObject(
                    yup.object().shape({
                        username: yup.mixed().test('smscode', 'Please enter your sms code', (value) => {
                            return value && REG_EXP.NO_EMPTY.test(value)
                        }),
                    }),
                    values,
                )
                if (errors) {
                    return errors
                }
                doSMS(values)
            }
        },
        [router, showlogin, showSMSCode],
    )

    return (
        <Provider store={store}>
            <React.Fragment>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <link
                        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
                        rel="stylesheet"
                    />
                    <link rel="stylesheet" href="/_css/bootstrap.min.css" />
                    <link rel="stylesheet" href="/_css/style.css?v=1.0" />
                    <link rel="stylesheet" href="/_css/modal.css?v=1.0" />
                    <link rel="stylesheet" href="/_css/responsive.css?v=1.0" />
                    <link rel="stylesheet" href="/_css/textarea-fix.css?v=1.0.9" />
                    <title>Login</title>

                    <script
                        async={true}
                        src={`https://www.googletagmanager.com/gtag/js?id=${props.env.GOOGLE_ANALYTICS_KEY}`}
                    ></script>
                    <script
                        dangerouslySetInnerHTML={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __html: `
                         window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${props.env.GOOGLE_ANALYTICS_KEY}',
                        {
                            'custom_map': {
                            'dimension1': 'account_id',
                            'dimension2': 'project_id',
                             'dimension5': 'user_id',   
                             'dimension6': 'user_name',                     
                           }
                        });          
                    `,
                        }}
                    />
                    {/*}
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:1368493,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                        }}
                    />
                    */}
                    {/*
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `var Tawk_API=Tawk_API || {},
                            Tawk_LoadStart=new Date();
                        (function() {
                        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                        s1.async=true;
                        s1.src='https://embed.tawk.to/5d0f70d653d10a56bd7b7103/default';
                        s1.charset='UTF-8';
                        s1.setAttribute('crossorigin','*');
                        s0.parentNode.insertBefore(s1,s0);
                    })();`,
                        }}
                    />
                    */}
                </Head>
                <div className="h-100 full-height login-con" style={{ backgroundColor: 'black' }}>
                    <div className="container-fluid h-100 d-flex flex-column">
                        <div className="row h-100">
                            <div className="col-md-6 ">
                                <div className="row justify-content-center align-items-center h-100">
                                    <div className="col-md-7">
                                        <small className="welcome-text">Welcome!</small>
                                        <h3 className="title">Log in to your account</h3>
                                        <div
                                            className="container position-relative login-error"
                                            id="msgCon"
                                            style={{ display: 'none' }}
                                        >
                                            <div className="row">
                                                <div className="col-md-10 d-flex justify-content-center">
                                                    <div className="message">
                                                        <i className="material-icons">check</i>{' '}
                                                        <span className="text">your project was saved</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Form
                                            onSubmit={handleSubmit}
                                            render={({ handleSubmit, form, submitting, pristine, values }) => {
                                                return (
                                                    <form id="loginForm">
                                                        {showlogin && (
                                                            <React.Fragment key={'login'}>
                                                                <div className="form-group">
                                                                    <label htmlFor="userName">Email</label>
                                                                    <Field
                                                                        name="username"
                                                                        component={InputAdapter}
                                                                        className="form-control"
                                                                        id="userName"
                                                                        placeholder="janedoe@domain.com"
                                                                    />
                                                                </div>
                                                                <span className="error" />
                                                                <div className="form-group pass-con">
                                                                    <label htmlFor="userPass">
                                                                        Enter your password
                                                                    </label>
                                                                    <div className="form-group mb-2 position-relative">
                                                                        <Field
                                                                            component={InputAdapter}
                                                                            type={passwordVisible ? 'text' : 'password'}
                                                                            name="password"
                                                                            className="form-control g-app"
                                                                            id="userPass"
                                                                            placeholder="Password"
                                                                        />
                                                                        <a
                                                                            href="#"
                                                                            id="showPass"
                                                                            onClick={togglePasswordVisible}
                                                                        >
                                                                            <i className="material-icons">
                                                                                {passwordVisible
                                                                                    ? 'visibility_off'
                                                                                    : 'remove_red_eye'}
                                                                            </i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <span className="error" />
                                                                <div className="form-group pass-con">
                                                                    <small
                                                                        id="forgotPassword"
                                                                        className="form-text text-right text-muted forgot-link-con"
                                                                    >
                                                                        <a href="#">Forgot your password?</a>
                                                                    </small>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        {showSMSCode && (
                                                            <React.Fragment key={'smscoe'}>
                                                                <div className="form-group" style={{ display: 'none' }}>
                                                                    From Feb 1st 2021, Seecares will enforce stronger
                                                                    security checks, please update your phone number,
                                                                    contact your account manager or contact our
                                                                    <a
                                                                        href={'https://seecares.com/suport'}
                                                                        target={'new'}
                                                                    >
                                                                        support team
                                                                    </a>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="smscode">SMS Code</label>
                                                                    <Field
                                                                        name="smscode"
                                                                        component={InputAdapter}
                                                                        className="form-control"
                                                                        id="smscode"
                                                                        placeholder="janedoe@domain.com"
                                                                    />
                                                                </div>
                                                                <span className="error" />

                                                                <div className="form-group pass-con">
                                                                    <small className="form-text text-right text-muted forgot-link-con">
                                                                        <a href="#" onClick={resendSmsCode}>
                                                                            Click here to resend SMS
                                                                        </a>
                                                                    </small>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary btn-lg btn-block btn-blue"
                                                            onClick={handleSubmit}
                                                        >
                                                            {showlogin ? 'Log in' : 'Verify'}
                                                        </button>
                                                    </form>
                                                )
                                            }}
                                        />
                                        {showlogin && (
                                            <React.Fragment key={'otherlogin'}>
                                                <p className="or-text">OR</p>
                                                <div className="social-login-con">
                                                    <a href={props.linkedInLink}>
                                                        <img src="/_img/linked_in.svg" />
                                                    </a>
                                                    <a href={props.googleLink}>
                                                        <img src="/_img/google.svg" />
                                                    </a>
                                                </div>
                                                <div className="new-cust">
                                                    <p>
                                                        New to seecares?{' '}
                                                        <a href="mailto:info@seecares.com">Contact us for details</a>
                                                    </p>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {false && !isMFAEnabled && showSMSCode && (
                                            <div className="form-group pass-con">
                                                <small className="form-text text-right text-muted forgot-link-con">
                                                    <a href="#" onClick={skipForNow}>
                                                        Skip sms code for now
                                                    </a>
                                                </small>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 login-cover" />
                        </div>
                    </div>
                </div>
                <StatusMessage containerWidth={'50%'} />
            </React.Fragment>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .login-cover {
                        background: linear-gradient(180deg, #3f3356 0%, rgba(63, 51, 86, 0) 100%),
                            url('${require('../../../images/cover-login-gradient.png')}');
                        background-position: bottom;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                `}
            </style>
        </Provider>
    )
}
